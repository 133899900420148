import {useSetTitleOnResponseError} from './use-set-title-on-response-error'

/**
 * By wrapping an application's custom error boundary with this component,
 * applications will get framework level error boundary support, such as
 * updating the title on a navigation error.
 */
export const RouterErrorBoundary = ({children}: {children?: React.ReactNode}) => {
  useSetTitleOnResponseError()
  return children
}

try{ RouterErrorBoundary.displayName ||= 'RouterErrorBoundary' } catch {}
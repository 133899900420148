import {memo, type ComponentType, type ReactNode} from 'react'
import {Outlet, useLocation, useNavigation, type RouteObject} from 'react-router-dom'
import {useSoftNavLifecycle} from '../use-soft-nav-lifecycle'
import {CommonElements} from '../CommonElements'
import {RouterErrorBoundary} from './RouterErrorBoundary'
import {BaseProviders} from '../BaseProviders'
import {AppPayloadContext} from '../use-app-payload'
import {RoutesContextProvider} from '../RoutesContextProvider'
import {NavigationFocusListener} from './NavigationFocusListener'
import {HistoryListener} from './HistoryListener'
import {CombinedScrollRestoration} from './ScrollRestoration'
import {PublishPayload} from './PublishPayload'

/**
 * We start the soft nav in the loader and finish it in the router.
 * The soft nav events start via startSoftNav when the loader is called
 * with the request in QueryRoute.
 *
 * SoftNavLifecycleListener is a component that listens to the soft nav
 * lifecycle and finishes the operation when complete.
 */
const SoftNavLifecycleListener = memo(function SoftNavLifecycleListener() {
  const location = useLocation()
  const navigation = useNavigation()
  const isNavigating = Boolean(navigation.location)
  useSoftNavLifecycle(location, isNavigating, null)
  return null
})

export function routesWithProviders(
  routes: RouteObject[],
  {
    ssrError,
    appName,
    wasServerRendered,
    children,
    HydrateFallback,
    dataRouterEnabled,
    appPayload,
  }: {
    appPayload?: Record<string, unknown>
    appName: string
    ssrError: HTMLScriptElement | undefined
    wasServerRendered: boolean
    children?: ReactNode
    HydrateFallback?: ComponentType
    dataRouterEnabled: boolean
  },
): RouteObject[] {
  return [
    {
      errorElement: <RouterErrorBoundary />,
      HydrateFallback,
      element: (
        <BaseProviders appName={appName} wasServerRendered={wasServerRendered} dataRouterEnabled={dataRouterEnabled}>
          <AppPayloadContext.Provider value={appPayload}>
            <RoutesContextProvider routes={routes}>
              <Outlet />
              {children}
              <CommonElements ssrError={ssrError} />
              <SoftNavLifecycleListener />
              <NavigationFocusListener />
              <HistoryListener />
              <CombinedScrollRestoration />
              <PublishPayload />
            </RoutesContextProvider>
          </AppPayloadContext.Provider>
        </BaseProviders>
      ),
      children: routes,
    },
  ]
}

try{ SoftNavLifecycleListener.displayName ||= 'SoftNavLifecycleListener' } catch {}
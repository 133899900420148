import type {queryOptions, QueryMeta} from '@tanstack/react-query'
import {reportTraceData} from '@github-ui/internal-api-insights'
import {reactFetchJSON} from '@github-ui/verified-fetch'
import {ResponseError} from './response-error'
import {QueryRouteQueryType, type QueryDepsFn} from './data-router-types'

// note the `any` for `RoutePath` here means we don't get fully typed `params` object in the queryDeps function
// but that should be fine since that's only internal to reusable query configs.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RelaxedQueryDepsFn<T> = (...args: Parameters<QueryDepsFn<any>>) => T

type QueryOptions<Deps> = Omit<Parameters<typeof queryOptions>[0], 'queryFn' | 'queryKey'> & {
  queryName?: string
  /**
   * Specialized version of query deps that returns the `string` url path from which to request data
   */
  queryDeps?: RelaxedQueryDepsFn<Deps>
}

/**
 * A relaxed version of {@link QueryRouteQueryConfig} that allows for a more independent API
 * at the cost of some type safety. Specifically, it relaxes many of the generics from `QueryRouteQueryConfig`
 */
export type RelaxedQueryRouteQueryConfig<Res, Deps> = QueryOptions<Deps> & {
  queryName: string
  /**
   * The queryFn to call.
   * This accepts dependencies returned from the queryDeps function if one exists and returns a response to cache.
   */
  queryFn: (
    queryKey: {
      appName: string
      routeId: string
      routePath: string
      queryName: string
      queryDeps: Deps
    },
    opts: {signal: AbortSignal; meta: QueryMeta | undefined},
  ) => Promise<Res> // The argument type of `queryFn` is tied to `queryDeps`
  /**
   * The {@link QueryRouteQueryType} type of query to initiate
   */
  type?: QueryRouteQueryType
}

export function initialQuery<Res>(opts: QueryOptions<string> = {}): RelaxedQueryRouteQueryConfig<Res, string> {
  return {
    queryName: 'initialQuery',
    queryDeps: ({pathname}) => pathname,
    queryFn: async ({routeId, queryName, queryDeps: pathname}) => {
      const response = await reactFetchJSON(pathname)
      if (!response.ok) throw new ResponseError(response.statusText, response)
      const json = await response.json()
      reportTraceData(json)
      return json.payload?.[routeId]?.[queryName]
    },
    type: QueryRouteQueryType.Blocking,
    ...opts,
  }
}

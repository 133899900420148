import {useLayoutEffect} from '@github-ui/use-layout-effect'
import {useLocation} from 'react-router-dom'

// This disables turbo trying to make a request to restore the page when we soft navigate with react
// by providing the skipTurbo flag.
// We no longer have access to the BrowserHistory object in react router, so we can't use `.listen()`.
// Instead, we listen for location changes and update the history state on every location change.
export function HistoryListener() {
  const location = useLocation()
  useLayoutEffect(() => {
    const {turbo, ...state} = history.state

    history.replaceState({...state, skipTurbo: true}, '', window.location.href)
  }, [location.key])

  return null
}

try{ HistoryListener.displayName ||= 'HistoryListener' } catch {}